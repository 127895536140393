import { i18n } from '@/plugins/i18n'

export default [
  {
    meta: {
      title: i18n.tc('ConsultInventory', 2)
    },
    path: '/inventories',
    name: 'inventories-index',
    component: () => import(/* webpackChunkName: "inventories-index" */ '@/views/inventories/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('InventoryMovement', 2)
    },
    path: '/inventories/detail',
    name: 'inventories-detail',
    component: () => import(/* webpackChunkName: "inventories-detail-index" */ '@/views/inventories/detail/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('AdjustStock')
    },
    path: '/inventories/adjust-stock',
    name: 'inventories-adjust-stock',
    component: () => import(/* webpackChunkName: "inventories-adjust-stock" */ '@/views/inventories/AdjustStock.vue')
  },
  {
    meta: {
      title: i18n.tc('AdjustCost')
    },
    path: '/inventories/adjust-costs',
    name: 'inventories-adjust-costs',
    component: () => import(/* webpackChunkName: "inventories-adjust-costs" */ '@/views/inventories/AdjustCosts.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateInventory')
    },
    path: '/inventories/create/:type',
    name: 'inventories-create',
    component: () => import(/* webpackChunkName: "inventories-create" */ '@/views/inventories/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditInventory')
    },
    path: '/inventories/edit/:id',
    name: 'inventories-edit',
    component: () => import(/* webpackChunkName: "inventories-edit" */ '@/views/inventories/Edit.vue')
  },
  {
    meta: {
      title: i18n.tc('InventoryTransfer')
    },
    path: '/inventories/transfers',
    name: 'inventories-transfers-index',
    component: () => import(/* webpackChunkName: "inventories-transfers" */ '@/views/inventories/transfers/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('InventoryTransfer')
    },
    path: '/inventories/transfers/create',
    name: 'inventories-transfers-create',
    component: () => import(/* webpackChunkName: "inventories-transfers-create" */ '@/views/inventories/transfers/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('InventoryTransfer')
    },
    path: '/inventories/transfers/:id/duplicate',
    name: 'inventories-transfers-duplicate',
    component: () => import(/* webpackChunkName: "inventories-transfers-duplicate" */ '@/views/inventories/transfers/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditInventoryTransfer')
    },
    path: '/inventories/transfers/:id/edit',
    name: 'inventories-transfers-edit',
    component: () => import(/* webpackChunkName: "inventories-transfers-edit" */ '@/views/inventories/transfers/Edit.vue')
  },
  {
    meta: {
      title: i18n.tc('InventoryTransfer')
    },
    path: '/inventories/transfers/:id/detail',
    name: 'inventories-transfers-detail',
    component: () => import(/* webpackChunkName: "inventories-transfers-edit" */ '@/views/inventories/transfers/Detail.vue')
  },
  {
    meta: {
      title: i18n.tc('InventoryLoss')
    },
    path: '/inventories/loss',
    name: 'inventories-loss',
    component: () => import(/* webpackChunkName: "inventories-loss" */ '@/views/inventories/Loss.vue')
  },
  {
    meta: {
      title: 'Requisición de inventario'
    },
    path: '/inventories/requisitions',
    name: 'inventories-requisitions',
    component: () => import('@/views/inventories/requisitions/Index.vue')
  },
  {
    meta: {
      title: 'Crear requisición'
    },
    path: '/inventories/requisitions/create',
    name: 'inventories-requisitions-create',
    component: () => import('@/views/inventories/requisitions/Create.vue')
  },
  {
    meta: {
      title: 'Editar requisición'
    },
    path: '/inventories/requisitions/edit/:id',
    name: 'inventories-requisitions-edit',
    component: () => import('@/views/inventories/requisitions/Edit.vue')
  }
]
